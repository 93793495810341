<template>
    <div class="holder">
        <v-card
            :loading="loading"
            max-width="374"
            outlined
        >
            <v-img
                class="mt-5 mb-5"
                contain
                height="100"
                src="../assets/images/a.png"
            ></v-img>

            <!--            <v-card-title>{{ $t('login.loading') }}</v-card-title>-->

            <div class="pr-5 pl-5 pt-4">
                <v-btn
                    :loading="loading"
                    block
                    color="secondary"
                    elevation="2"
                    @click="login"
                >{{ $t("login.my_stc") }}
                </v-btn>
            </div>

            <div class="text-overline ml-4 mr-4 mt-4">
                &copy; STAN-TAB CORP. LTD
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "Login",
    mounted() {
        window.document.title = this.$t("login.title");

    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        login() {
            this.loading = true;
            this.$auth.loginWithRedirect();
        }
    }
};
</script>

<style scoped>
.holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>